import React, { useState, useEffect, useCallback } from "react";
import {
  IonGrid,
  IonRow,
  IonContent,
  IonPage,
  // IonFooter,
  IonToolbar,
  // IonToast,
  IonHeader,
  IonTitle,
  IonProgressBar,
} from "@ionic/react";

import "./Home.css";
import Cookies from "universal-cookie";
import { v4 as uuidv4 } from "uuid";
import ItemRenderer from "../components/itemrender";
import { useTransition, animated } from "react-spring";
import Footer from "../components/footercharite.jsx";

const Home = () => {
  const [transitionDirect, setTransitionDirect] = useState(null);
  const [pageData, setPageData] = useState(null);
  const [pageready, setPageReady] = useState(false);
  const [mediaData, setMediaData] = useState(null);
  const [responseData, setResponseData] = useState(null);
  // const [checkValid, setCheckValid] = useState(false);
  // const [showToast1, setShowToast1] = useState(false);
  const [pageprogress, setPageProgress] = useState(null);
  const [complete, setComplete] = useState(true);
  // const [current, setCurrent] = useState(null);
  // const [footerStatus, setFooterStatus] = useState(true);
  const transitions = useTransition(complete, null, {
    config: { duration: 250 },
    initial: { transform: `translateX(0px)`, opacity: 1 },
    from: { transform: transitionDirect && transitionDirect.from, opacity: 0 },
    enter: { transform: `translateX(0px)`, opacity: 1 },
    leave: {
      transform: transitionDirect && transitionDirect.leave,
      opacity: 0,
    },
    onDestroyed: () => setComplete(true),
  });

  const pageReset = async () => {
    setPageData(null);
    setResponseData(null);
    // setCheckValid(false);
    // setFooterStatus(true);
  };

  const getData = useCallback(async () => {
    try {
      if (complete) {
        await pageReset();
        const cookies = new Cookies();
        if (!cookies.get("sessionIdCharite")) {
          cookies.set("sessionIdCharite", uuidv4(), {
            path: "/",
            expires: new Date(Date.now() + 22222592000),
          });
        }
        const initfetch = await fetch(
          process.env.NODE_ENV === "production"
            ? `https://fb-backend.procuratio.com/charite/getsurvey/`
            : `http://192.168.8.246:3000/charite/getsurvey/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              sessionId: cookies.get("sessionIdCharite"),
            }),
          }
        );

        const data = await initfetch.json();

        setPageData(data.pages);
        setMediaData(data.media);
      }
    } catch (error) {
      console.log(error);
    }
  }, [complete]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (pageData && pageData.index === 6) {
      const cookies = new Cookies();
      cookies.remove("sessionIdCharite", { path: "/" });
    }
  }, [pageData]);

  useEffect(() => {
    pageData && setPageProgress(pageData.progress);
  }, [pageData]);

  useEffect(() => {
    // console.log(responseData);
    if (pageData && responseData === null) {
      if (pageData.responseData) {
        setResponseData(pageData.responseData);
      } else {
        let init = null;
        pageData.elements
          // .filter((e) => e.name && e.type !== "textblock")
          .forEach((e, ind, arr) => {
            init = {
              ...init,
              [e.name]: null,
            };
            if (arr.length - 1 === ind) {
              setResponseData(init);
            }
          });
      }
    }
  }, [pageData, responseData]);

  useEffect(() => {
    if (pageData && responseData) {
      setPageReady(true);
    }
  }, [pageData, responseData]);

  const postData = useCallback(
    async (trigger) => {
      try {
        const cookies = new Cookies();
        const initpost = await fetch(
          process.env.NODE_ENV === "production"
            ? `https://fb-backend.procuratio.com/charite/postsurvey/`
            : `http://192.168.8.246:3000/charite/postsurvey/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              trigger: trigger,
              sessionId: cookies.get("sessionIdCharite"),
              surveyPageResponse: { ...pageData, responseData },
            }),
          }
        );

        const data = await initpost.json();

        console.log(data);
      } catch (e) {
        console.log(e);
      } finally {
        setComplete(false);
      }
    },
    [pageData, responseData]
  );

  // useEffect(() => {
  //   if (responseData && Object.values(responseData).every((o) => o !== null)) {
  //     if (current !== "checkbox" && current !== "textbox") {
  //       setTransitionDirect({
  //         from: `translateX(100px)`,
  //         leave: `translateX(-100px)`,
  //       });
  //       postData("next");
  //     }
  //     // else {
  //     //   setFooterStatus(false);
  //     // }
  //   }
  // }, [responseData, pageData, current, postData]);

  const handleSubmit = async (e) => {
    setPageReady(false);
    if (e === "next") {
      // setCheckValid(true);
      // const test = Object.keys(responseData).some(
      //   (k) => responseData[k] === null
      // );

      // if (test === true) {
      //   setShowToast1(true);
      //   return;
      // } else {
      setTransitionDirect({
        from: `translateX(100px)`,
        leave: `translateX(-100px)`,
      });
      postData(e);
      return;
      // }
    }
    if (e === "prev") {
      setTransitionDirect({
        from: `translateX(-100px)`,
        leave: `translateX(100px)`,
      });
      postData(e);
      return;
    }
    // getData();
  };

  return (
    // ((pageData && responseData) ||
    //   (pageData && pageData.name === "Vielen Dank")) && (

    <IonPage
      style={{
        background: `linear-gradient(
      rgba(255, 255, 255, 0.87),
      rgba(255, 255, 255, 0.87)
    ),
    url(${mediaData && mediaData.bg}) no-repeat center center / cover`,
      }}
    >
      <IonHeader className="ion-no-border">
        <IonToolbar className="toolheader">
          <IonTitle style={{ fontFamily: "Open Sans" }}>
            {pageData && pageData.title}
          </IonTitle>
          <IonProgressBar color="medium" value={pageprogress}></IonProgressBar>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow style={{ justifyContent: "center", paddingBottom: "20px" }}>
            <img
              className="charite-logo"
              src={mediaData && mediaData.logo}
              alt="logo"
            />
          </IonRow>

          {transitions.map(({ item, key, props }) => {
            return (
              item && (
                <animated.div key={key} style={{ ...props }}>
                  {pageData &&
                    responseData &&
                    pageData.elements
                      .filter((item) => Object.keys(item).length)
                      .map((e, ind) => {
                        return (
                          <ItemRenderer
                            // checkValid={checkValid}
                            getData={getData}
                            setResponseData={setResponseData}
                            responseData={responseData}
                            key={ind}
                            item={e}
                            // setCurrent={setCurrent}
                          />
                        );
                      })}
                </animated.div>
              )
            );
          })}
        </IonGrid>
      </IonContent>
      {/* <IonToast
        color="warning"
        isOpen={showToast1}
        onDidDismiss={() => setShowToast1(false)}
        message="Bitte beantworten Sie alle Fragen, um fortzufahren"
        duration={3000}
        position="top"
      /> */}

      <Footer
        // footerStatus={footerStatus}
        pageready={pageready}
        pageData={pageData}
        handleSubmit={handleSubmit}
      />
    </IonPage>

    // )
  );
};

export default Home;

import React, { useState, useEffect, useCallback } from "react";
import {
  IonGrid,
  IonRow,
  IonContent,
  IonPage,
  // IonFooter,
  IonToolbar,
  // IonToast,
  IonHeader,
  IonTitle,
  IonProgressBar,
} from "@ionic/react";

import { useParams, useHistory } from "react-router-dom";

import "./Home.css";
import Cookies from "universal-cookie";
import { v4 as uuidv4 } from "uuid";
import ItemRendererDyn from "../components/itemrender(dyn)";
import { useTransition, animated } from "react-spring";
import FooterDyn from "../components/footer(dyn).jsx";

const SurveyRenderer = (props) => {
  let { surveyid } = useParams();
  let history = useHistory();

  const [transitionDirect, setTransitionDirect] = useState(null);
  const [pageData, setPageData] = useState(null);
  const [clientData, setClientData] = useState(null);
  const [pageready, setPageReady] = useState(false);
  const [mediaData, setMediaData] = useState(null);
  const [firstLastPage, setFirstLastPage] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const [requiredQuestionCheck, setRequiredQuestionCheck] = useState(true);
  // const [checkValid, setCheckValid] = useState(false);
  // const [showToast1, setShowToast1] = useState(false);
  const [pageprogress, setPageProgress] = useState(null);
  const [complete, setComplete] = useState(true);
  const [getComplete, setGetComplete] = useState(false);
  // const [current, setCurrent] = useState(null);
  // const [footerStatus, setFooterStatus] = useState(true);
  const transitions = useTransition(complete, null, {
    config: { duration: 250 },
    initial: { transform: `translateX(0px)`, opacity: 1 },
    from: { transform: transitionDirect && transitionDirect.from, opacity: 0 },
    enter: { transform: `translateX(0px)`, opacity: 1 },
    leave: {
      transform: transitionDirect && transitionDirect.leave,
      opacity: 0,
    },
    onDestroyed: () => setComplete(true),
  });

  const pageReset = async () => {
    setPageData(null);
    setResponseData(null);
    setGetComplete(false);
    setFirstLastPage(null);
    setPageProgress(null);
    setRequiredQuestionCheck(true);
    // setCheckValid(false);
    // setFooterStatus(true);
  };

  const getData = useCallback(async () => {
    try {
      if (complete) {
        await pageReset();
        const cookies = new Cookies();
        if (!cookies.get(`surveySessionId${surveyid}`)) {
          let d = new Date();
          d.setHours(24, 0, 0, 0);
          cookies.set(`surveySessionId${surveyid}`, uuidv4(), {
            path: "/",
            expires: d,
          });
        }

        const cookieArr = Object.keys(cookies.getAll());

        const res = cookieArr.filter(
          (e) =>
            e.includes("surveySessionId") && e !== `surveySessionId${surveyid}`
        );

        res.length && res.forEach((e) => e && cookies.remove(e, { path: "/" }));

        const initfetch = await fetch(
          process.env.NODE_ENV === "production"
            ? `https://fb-backend.procuratio.com/client/getsurvey/`
            : `http://localhost:3000/client/getsurvey/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              sessionId: cookies.get(`surveySessionId${surveyid}`),
              surveyid,
            }),
          }
        );

        const data = await initfetch.json();

        console.log(data);

        // console.log(data.media);

        setPageData(data.pages);
        setClientData(data.clientid);

        data.responseData && setResponseData(data.responseData.responseData);

        data.media && setMediaData(data.media);
        data.firstpage && setFirstLastPage("first");
        data.finalpage && setFirstLastPage("last");
        data.surveyprogress && setPageProgress(data.surveyprogress);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setGetComplete(true);
    }
  }, [complete, surveyid]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (firstLastPage === "last") {
      const cookies = new Cookies();
      cookies.remove(`surveySessionId${surveyid}`, { path: "/" });
    }
  }, [firstLastPage, surveyid]);

  // useEffect(() => {
  //   pageData && setPageProgress(pageData.progress);
  // }, [pageData]);

  useEffect(() => {
    if (pageData && getComplete && responseData === null) {
      let init = [];
      pageData.elements
        // .filter((e) => e.name && e.type !== "textblock")
        .forEach((e, ind, arr) => {
          init.push({
            pagetitle: pageData.title,
            type: e.type,
            title: e.title,
            elementid: e._id,
            question: e.question,
            ...(e.type === "checkbox" ? { response: [] } : { response: null }),
          });
          if (arr.length - 1 === ind) {
            setResponseData(init);
          }
        });
    }
  }, [pageData, responseData, getComplete]);

  useEffect(() => {
    if (pageData && responseData) {
      setPageReady(true);
    }
  }, [pageData, responseData]);

  useEffect(() => {
    if (responseData) {
      console.log(responseData);
      if (
        responseData.filter((e) => e.required === true && e.response === null)
          .length === 0
      ) {
        setRequiredQuestionCheck(false);
      }
    }
  }, [responseData]);

  const postData = useCallback(
    async (trigger) => {
      try {
        const cookies = new Cookies();
        const initpost = await fetch(
          process.env.NODE_ENV === "production"
            ? `https://fb-backend.procuratio.com/client/postsurvey/${props.location.search}`
            : `http://localhost:3000/client/postsurvey/${props.location.search}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              trigger: trigger,
              surveyId: surveyid,
              clientid: clientData,
              sessionId: cookies.get(`surveySessionId${surveyid}`),
              surveyPageResponse: {
                title: pageData.title,
                responseData: responseData,
                pageid: pageData._id,
              },
            }),
          }
        );

        const data = await initpost.json();

        console.log(data);
      } catch (e) {
        console.log(e);
      } finally {
        setComplete(false);
        history.replace(`/dynamic/${surveyid}`);
      }
    },
    [
      pageData,
      responseData,
      surveyid,
      props.location.search,
      clientData,
      history,
    ]
  );

  // useEffect(() => {
  //   if (responseData && Object.values(responseData).every((o) => o !== null)) {
  //     if (current !== "checkbox" && current !== "textbox") {
  //       setTransitionDirect({
  //         from: `translateX(100px)`,
  //         leave: `translateX(-100px)`,
  //       });
  //       postData("next");
  //     }
  //     // else {
  //     //   setFooterStatus(false);
  //     // }
  //   }
  // }, [responseData, pageData, current, postData]);

  const handleSubmit = async (e) => {
    document.getElementById( 'kundenlogo' ).scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });

    setPageReady(false);
    if (e === "next") {
      // setCheckValid(true);
      // const test = Object.keys(responseData).some(
      //   (k) => responseData[k] === null
      // );

      // if (test === true) {
      //   setShowToast1(true);
      //   return;
      // } else {
      setTransitionDirect({
        from: `translateX(100px)`,
        leave: `translateX(-100px)`,
      });
      postData(e);
      return;
      // }
    }
    if (e === "prev") {
      setTransitionDirect({
        from: `translateX(-100px)`,
        leave: `translateX(100px)`,
      });
      postData(e);
      return;
    }
    // getData();
  };

  return (
    // ((pageData && responseData) ||
    //   (pageData && pageData.name === "Vielen Dank")) && (

    <IonPage
      style={{
        background: `linear-gradient(
      rgba(255, 255, 255, 0.87),
      rgba(255, 255, 255, 0.87)
    ),
    url(${mediaData && mediaData.bg}) no-repeat center center / cover`,
      }}
    >
      <IonHeader className="ion-no-border">
        <IonToolbar className="toolheader">
          <IonTitle style={{ fontFamily: "Open Sans" }}>
            {pageData && pageData.title}
          </IonTitle>
          <IonProgressBar color="medium" value={pageprogress}></IonProgressBar>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <div className="maincontent">
            <IonRow style={{ justifyContent: "center", paddingBottom: "20px" }}>
              <img
                className="charite-logo"
                src={mediaData && mediaData.logo}
                alt="logo"
                id="kundenlogo"
              />
            </IonRow>

            {transitions.map(({ item, key, props }) => {
              return (
                item && (
                  <animated.div key={key} style={{ ...props }}>
                    {pageData &&
                      responseData &&
                      pageData.elements
                        .filter((item) => Object.keys(item).length)
                        .map((e, ind) => {
                          return (
                            <ItemRendererDyn
                              // checkValid={checkValid}
                              getData={getData}
                              setResponseData={setResponseData}
                              // responseData={responseData.filter(
                              //   (el) =>
                              //     el.elementid.toString() === e._id.toString()
                              // )}
                              responseData={responseData}
                              key={ind}
                              item={e}
                              indexno={ind}
                              // setCurrent={setCurrent}
                            />
                          );
                        })}
                  </animated.div>
                )
              );
            })}
          </div>
        </IonGrid>
      </IonContent>
      {/* <IonToast
        color="warning"
        isOpen={showToast1}
        onDidDismiss={() => setShowToast1(false)}
        message="Bitte beantworten Sie alle Fragen, um fortzufahren"
        duration={3000}
        position="top"
      /> */}

      <FooterDyn
        mediaData = { mediaData }
        requiredQuestionCheck={requiredQuestionCheck}
        firstLastPage={firstLastPage}
        // footerStatus={footerStatus}
        pageready={pageready}
        pageData={pageData}
        handleSubmit={handleSubmit}
      />
    </IonPage>

    // )
  );
};

export default SurveyRenderer;

import React, { useState, useEffect } from "react";
import Rating from "@material-ui/lab/Rating";
// import Box from "@material-ui/core/Box";
import {
  IonItem,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonRow,
  IonList,
  // IonRadioGroup,
  IonButton,
  // IonListHeader,
  // IonRadio,
  IonCheckbox,
  IonItemDivider,
  IonTextarea,
  IonCard,
  // IonCardHeader,
  // IonCardSubtitle,
  // IonCardTitle,
  IonCardContent,
  IonGrid,
  IonCol,
} from "@ionic/react";
// import { makeStyles } from "@material-ui/core/styles";
// import Alert from "@material-ui/lab/Alert";

// const labels = {
//   1: "Useless+",
//   2: "Poor+",
//   3: "Ok+",
//   4: "Good+",
//   5: "Excellent+",
// };

// const useStyles = makeStyles((theme) => ({
//   alert: {
//     width: "100%",
//     "& > * + *": {
//       marginTop: theme.spacing(2),
//     },
//   },
// }));

const ItemRenderer = ({
  item,
  setResponseData,
  responseData,
  // checkValid,
  // setHiddenAdvState,
  // hiddenAdvState,
  // current,
  // setCurrent,
  // setHidden,
}) => {
  const [response, setResponse] = useState(responseData[item.name]);
  const [value, setValue] = useState(responseData[item.name]);
  const [cardState, setCardState] = useState();
  const [buttState, setButtState] = useState(responseData[item.name]);
  // const [textfieldactive, setTextfieldActive] = useState(false);
  const [hidden, setHidden] = useState();

  // const [hover, setHover] = useState(-1);
  // const classes = useStyles();

  const checkboxHandler = (e) => {
    if (e.detail.checked) {
      setResponseData({
        ...responseData,
        [item.name]: {
          ...responseData[item.name],
          [e.detail.value.value]: e.detail.value,
        },
      });
    }
    if (!e.detail.checked) {
      setResponseData({
        ...responseData,
        [item.name]: {
          ...responseData[item.name],
          [e.detail.value.value]: null,
        },
      });
    }
  };

  const responseHandler = (e) => {
    // setCurrent(item.type);
    if (item.type === "textbox") {
      setResponse(e.detail.value);
      // setTextfieldActive((prevState) => !prevState);

      // textfieldactive &&
      setResponseData({
        ...responseData,
        [item.name]: e.detail.value,
      });

      return;
    }
    if (item.type === "boolean") {
      setButtState(e.target.id);
      setResponseData({
        ...responseData,
        [item.name]: e.target.id,
      });
      if (item.reset && item.reset.triggerself === e.target.id) {
        const obj = {
          ...responseData,
          [item.name]: e.target.id,
        };

        const { [item.reset.target]: remove, ...newResponse } = obj;

        setResponseData(newResponse);
      } else {
        setResponseData({
          ...responseData,
          [item.name]: e.target.id,

          ...(item.createsub &&
            item.createsub.triggerself === e.target.id && {
              [item.createsub.target]: null,
            }),
        });
      }
      return;
    }
    if (item.type === "cards") {
      setCardState(e);
      setResponseData({
        ...responseData,
        [item.name]: e,
      });
      return;
    }
    if (item.type === "rating" || item.type === "rating10") {
      setValue(e);
      setResponseData({
        ...responseData,
        [item.name]: e,
      });
      return;
    }

    setResponse(e.detail.value);

    if (item.type === "checkbox") {
      checkboxHandler(e);
      return;
    }

    if (item.reset && item.reset.triggerself === e.detail.value) {
      const obj = {
        ...responseData,
        [item.name]: e.detail.value,
      };

      const { [item.reset.target]: remove, ...newResponse } = obj;

      setResponseData(newResponse);
    } else {
      setResponseData({
        ...responseData,
        [item.name]: e.detail.value,

        ...(item.createsub &&
          item.createsub.triggerself === e.detail.value && {
            [item.createsub.target]: null,
          }),
      });
    }
  };

  const compareWith = (o1, o2) => {
    return o1 && o2 ? o1.text === o2.text : o1 === o2;
  };

  const customAlertOptions = {
    cssClass: "custom-button-class",
  };

  useEffect(() => {
    // console.log(item);
    item.hidden && responseData
      ? responseData[item.hidden.target] === item.hidden.condition
        ? setHidden(false)
        : setHidden(true)
      : setHidden(false);
  }, [item, responseData]);

  // useEffect(() => {
  //   if (hidden === false) {
  //     if (item.type === "checkbox" || item.type === "textbox") {
  //       setHiddenAdvState({ ...hiddenAdvState, [item.name]: "nogo" });
  //     }
  //   }
  //   if (hidden === true) {
  //     setHiddenAdvState({ ...hiddenAdvState, [item.name]: "go" });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [hidden]);

  return (
    hidden === false && (
      <>
        <IonRow
        // className={
        //   checkValid && responseData[item.name] === null && "errorback"
        // }
        // style={{
        //   padding: "5px",
        // }}
        >
          {item.type === "dropdown" && (
            <>
              <IonLabel
                style={{
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  margin: "10px",
                }}
              >
                {item.title}
              </IonLabel>

              <IonItem style={{ width: "100%" }}>
                <IonSelect
                  className="selectCss"
                  style={{ fontFamily: "Open Sans" }}
                  placeholder="bitte wählen"
                  interfaceOptions={customAlertOptions}
                  compareWith={compareWith}
                  value={response}
                  okText="Ok"
                  cancelText="Cancel"
                  onIonChange={(e) => responseHandler(e)}
                >
                  {item.choices.map((el, ind) => (
                    <IonSelectOption value={el} key={ind}>
                      <div style={{ fontFamily: "Open Sans", fontWeight: 600 }}>
                        {el.text}
                      </div>
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            </>
          )}
          {item.type === "cards" && (
            <>
              <IonLabel
                style={{
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  margin: "10px",
                }}
              >
                {item.title}
              </IonLabel>

              <div style={{ width: "100%" }}>
                <IonGrid>
                  <IonRow>
                    {item &&
                      item.choices.map((el, ind) => (
                        <IonCol key={ind} size="6">
                          <IonCard
                            className="ion-no-margin"
                            style={
                              cardState === el
                                ? {
                                    background: `grey linear-gradient(
                      rgba(255, 255, 255, 0.70),
                      rgba(255, 255, 255, 0.70)
                    )`,
                                    height: "100%",
                                    display: "flex",
                                  }
                                : { height: "100%", display: "flex" }
                            }
                            button
                            onClick={() => responseHandler(el)}
                          >
                            <div className="centerCenter">
                              <img
                                src={el.image}
                                alt={el.text}
                                className="boximage"
                              />
                            </div>
                            <IonCardContent
                              style={{
                                fontFamily: "Open Sans",
                                fontWeight: 600,
                                fontSize: "15px",
                                color: "black",
                              }}
                            >
                              {el.text}
                            </IonCardContent>
                          </IonCard>
                        </IonCol>
                      ))}
                  </IonRow>
                </IonGrid>
              </div>
            </>
          )}
          {item.type === "boolean" && (
            <>
              <IonLabel
                style={{
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  margin: "10px",
                }}
              >
                {item.title}
              </IonLabel>
              {/* <IonItem style={{ width: "100%" }}> */}
              <div style={{ width: "100%" }}>
                <IonGrid>
                  <IonRow>
                    <IonCol size="6">
                      <IonButton
                        // className="buttonCss"
                        expand="block"
                        size="medium"
                        color={buttState === "ja" ? "medium" : "light"}
                        id="ja"
                        onClick={(e) => responseHandler(e)}
                        // className={buttState === "ja" && "buttonCss"}
                        strong
                      >
                        Ja
                      </IonButton>
                    </IonCol>
                    <IonCol size="6">
                      <IonButton
                        // className="buttonCss"
                        expand="block"
                        strong
                        size="medium"
                        color={buttState === "nein" ? "medium" : "light"}
                        id="nein"
                        onClick={(e) => responseHandler(e)}
                      >
                        Nein
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>

              {/* <IonList style={{ width: "50%", minWidth: "150px" }}>
                  <IonRadioGroup
                    value={response}
                    onIonChange={(e) => responseHandler(e)}
                  >
                    <IonItem lines="none">
                      <IonLabel style={{ fontFamily: "Open Sans" }}>
                        Ja
                      </IonLabel>
                      <IonRadio value={true} />
                    </IonItem>
                    <IonItem lines="none">
                      <IonLabel style={{ fontFamily: "Open Sans" }}>
                        Nein
                      </IonLabel>
                      <IonRadio value={false} />
                    </IonItem>
                  </IonRadioGroup>
                </IonList> */}
              {/* </IonItem> */}
            </>
          )}
          {item.type === "checkbox" && (
            <IonList style={{ width: "100%" }}>
              <IonItemDivider style={{ fontFamily: "Open Sans" }}>
                warum nicht?
              </IonItemDivider>

              {item.choices.map((e, i) => (
                <IonItem lines="none" key={i}>
                  <IonLabel>{e.text}</IonLabel>
                  <IonCheckbox
                    style={{ fontFamily: "Open Sans" }}
                    onIonChange={(e) => responseHandler(e)}
                    slot="end"
                    value={e}
                    disabled={
                      responseData &&
                      responseData[item.name] &&
                      responseData[item.name][e.disable.target]
                        ? true
                        : false
                    }
                  />
                </IonItem>
              ))}
            </IonList>
          )}
          {item.type === "rating" && (
            <>
              <IonLabel
                style={{
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  margin: "10px",
                }}
              >
                {item.title}
              </IonLabel>

              <div className="ratingwrapper">

              <div className="outeralign">
                <div>{(item.left === null || item.left.length < 1) ? "" : item.left}</div>
                  <div>{(item.right === null || item.right.length < 1) ? "" : item.right}</div>
                </div>
                <Rating
                  size="large"
                  name={item.name}
                  value={value}
                  precision={1}
                  onChange={(_event, e) => {
                    responseHandler(e);
                  }}
                  // onChangeActive={(event, newHover) => {
                  //   setHover(newHover);
                  // }}
                />
                {/* {value !== null && (
                    <Box ml={2}>{labels[hover !== -1 ? hover : value]}</Box>
                  )} */}
              </div>
            </>
          )}
          {item.type === "rating10" && (
            <>
              <IonLabel
                style={{
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  margin: "10px",
                  width: "100%",
                }}
              >
                {item.title}
              </IonLabel>

              <div
                className="ratingwrapper"
                style={{
                  fontFamily: "Open Sans",
                  margin: "10px",
                }}
              >
                <div className="outeralign">
                <div>{(item.left === null || item.left.length < 1) ? "" : item.left}</div>
                  <div>{(item.right === null || item.right.length < 1) ? "" : item.right}</div>
                </div>
                <Rating
                  max={10}
                  size="medium"
                  name={item.name}
                  value={value}
                  precision={1}
                  onChange={(event, e) => {
                    responseHandler(e);
                  }}
                  // onChangeActive={(event, newHover) => {
                  //   setHover(newHover);
                  // }}
                />
                {/* {value !== null && (
                    <Box ml={2}>{labels[hover !== -1 ? hover : value]}</Box>
                  )} */}
              </div>
            </>
          )}
          {item.type === "textbox" && (
            <>
              <IonLabel
                style={{
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  margin: "10px",
                  width: "100%",
                }}
              >
                {item.title}
              </IonLabel>
              <IonItem
                // className={!textfieldactive ? "textareacss" : ""}
                style={{ width: "100%" }}
                lines="none"
              >
                <IonTextarea
                  style={{
                    fontFamily: "Open Sans",
                  }}
                  placeholder={item.name}
                  // readonly={textfieldactive}
                  autofocus
                  rows={6}
                  cols={20}
                  value={response}
                  onIonChange={(e) => responseHandler(e)}
                  // onIonFocus={() => setTextfieldActive(true)}
                  // onIonBlur={() => setTextfieldActive(true)}
                ></IonTextarea>
              </IonItem>
              {/* 
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <IonButton
                  // className="buttonCss"

                  strong
                  size="medium"
                  color={textfieldactive ? "light" : "medium"}
                  id="fertig"
                  onClick={() => responseHandler(response)}
                >
                  {!textfieldactive ? "ändern" : "fertig"}
                </IonButton>
              </div> */}
            </>
          )}
          {item.type === "textblock" && (
            <div style={{ fontFamily: "Open Sans" }} className="textBlock">
              {item.text}
            </div>
          )}
          {/* {item.type !== "finalblock" &&
            checkValid &&
            responseData[item.name] === null && (
              <div className={classes.alert}>
                <Alert severity="error">
                  Please complete this question to proceed!
                </Alert>
              </div>
            )} */}
        </IonRow>
      </>
    )
  );
};

export default ItemRenderer;

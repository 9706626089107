import React, { useState, useEffect, useCallback } from "react";
import {
  IonGrid,
  IonRow,
  IonContent,
  IonPage,
  // IonFooter,
  IonToolbar,
  // IonToast,
  IonHeader,
  IonTitle,
  IonProgressBar,
} from "@ionic/react";

import "./Home.css";
import Cookies from "universal-cookie";
import { v4 as uuidv4 } from "uuid";
import ItemRenderer from "../components/itemrender(dyn)";
import { useTransition, animated } from "react-spring";
import Footer from "../components/footeruksh.jsx";

const Uksh = () => {
  const [transitionDirect, setTransitionDirect] = useState(null);
  const [pageData, setPageData] = useState(null);
  const [mediaData, setMediaData] = useState(null);
  const [responseData, setResponseData] = useState(null);
  // const [checkValid, setCheckValid] = useState(false);
  // const [hiddenAdvState, setHiddenAdvState] = useState();
  // const [footerStatus, setFooterStatus] = useState(true);
  // const [showToast1, setShowToast1] = useState(false);
  const [pageprogress, setPageProgress] = useState(null);
  const [complete, setComplete] = useState(true);
  const [current, setCurrent] = useState(null);
  const transitions = useTransition(complete, null, {
    config: { duration: 250 },
    initial: { transform: `translateX(0px)`, opacity: 1 },
    from: { transform: transitionDirect && transitionDirect.from, opacity: 0 },
    enter: { transform: `translateX(0px)`, opacity: 1 },
    leave: {
      transform: transitionDirect && transitionDirect.leave,
      opacity: 0,
    },
    onDestroyed: () => setComplete(true),
  });

  const pageReset = async () => {
    setPageData(null);
    setResponseData(null);
    // setCheckValid(false);
    // setFooterStatus(true);
  };

  const getData = useCallback(async () => {
    if (complete) {
      await pageReset();
      const cookies = new Cookies();
      if (!cookies.get("sessionIdProcuratio")) {
        cookies.set("sessionIdProcuratio", uuidv4(), {
          path: "/",
          expires: new Date(Date.now() + 22222592000),
        });
      }
      const initfetch = await fetch(
        process.env.NODE_ENV === "production"
          ? `https://fb-backend.procuratio.com/procuratio/getsurvey/`
          : `http://localhost:3000/procuratio/getsurvey/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            sessionId: cookies.get("sessionIdProcuratio"),
          }),
        }
      );

      const data = await initfetch.json();

      setPageData(data.pages);
      setMediaData(data.media);
    }
  }, [complete]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (pageData && pageData.index === 11) {
      const cookies = new Cookies();
      cookies.remove("sessionIdProcuratio", { path: "/" });
    }
  }, [pageData]);

  useEffect(() => {
    pageData && setPageProgress(pageData.progress);
  }, [pageData]);

  useEffect(() => {
    if (pageData && responseData === null) {
      let init = null;
      pageData.elements
        .filter((e) => e.name && e.type !== "textblock")
        .forEach((e, ind, arr) => {
          init = {
            ...init,
            [e.name]: null,
          };
          if (arr.length - 1 === ind) {
            setResponseData(init);
          }
        });
    }
  }, [pageData, responseData]);

  const postData = useCallback(
    async (trigger) => {
      try {
        const cookies = new Cookies();
        const initpost = await fetch(
          process.env.NODE_ENV === "production"
            ? `https://fb-backend.procuratio.com/procuratio/postsurvey/`
            : `http://localhost:3000/procuratio/postsurvey/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              trigger: trigger,
              sessionId: cookies.get("sessionIdProcuratio"),
              surveyPageResponse: { ...pageData, responseData },
            }),
          }
        );

        const data = await initpost.json();

        console.log(data);
      } catch (e) {
        console.log(e);
      } finally {
        setComplete(false);
      }
    },
    [pageData, responseData]
  );

  // useEffect(() => {
  //   console.log(hiddenAdvState);
  // }, [hiddenAdvState]);

  useEffect(() => {
    // console.log(pageData);
    // console.log(responseData);

    // if (
    //   responseData &&
    //   Object.values(responseData).filter((o) => o === null).length === 1
    // ) {
    //   const arr = Object.entries(responseData).filter(
    //     ([key, value]) => value === null
    //   )[0][0];
    //   if (
    //     pageData.elements.filter((e) => e.name === arr)[0].type === "checkbox"
    //   ) {
    //     console.log("kill scru");
    //   }
    // }

    if (responseData && Object.values(responseData).every((o) => o !== null)) {
      if (current !== "checkbox" && current !== "textbox") {
        setTransitionDirect({
          from: `translateX(100px)`,
          leave: `translateX(-100px)`,
        });
        postData("next");
      }
      // else {
      //   setFooterStatus(false);
      // }
    }
  }, [responseData, pageData, current, postData]);

  const handleSubmit = async (e) => {
    if (e === "next") {
      // setCheckValid(true);
      // const test = Object.keys(responseData).some(
      //   (k) => responseData[k] === null
      // );

      // if (test === true) {
      //   setShowToast1(true);
      //   return;
      // } else {
      setTransitionDirect({
        from: `translateX(100px)`,
        leave: `translateX(-100px)`,
      });
      postData(e);
      return;
      // }
    }
    if (e === "prev") {
      setTransitionDirect({
        from: `translateX(-100px)`,
        leave: `translateX(100px)`,
      });
      postData(e);
      return;
    }
    getData();
  };

  return (
    // ((pageData && responseData) ||
    //   (pageData && pageData.name === "Vielen Dank")) && (

    <IonPage
      style={{
        background: `linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    url(${mediaData && mediaData.bg}) no-repeat center center / cover`,
      }}
    >
      <IonHeader className="ion-no-border">
        <IonToolbar className="toolheader">
          <IonTitle style={{ fontFamily: "Open Sans" }}>
            {pageData && pageData.title}
          </IonTitle>
          <IonProgressBar color="primary" value={pageprogress}></IonProgressBar>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow style={{ justifyContent: "center" }}>
            <img
              className="uksh-logo"
              src={mediaData && mediaData.logo}
              alt="logo"
            />
          </IonRow>

          {transitions.map(({ item, key, props }) => {
            return (
              item && (
                <animated.div key={key} style={{ ...props }}>
                  {pageData &&
                    pageData.elements
                      .filter((item) => Object.keys(item).length)
                      .map((e, ind) => {
                        return (
                          <ItemRenderer
                            // checkValid={checkValid}
                            getData={getData}
                            setResponseData={setResponseData}
                            responseData={responseData}
                            key={ind}
                            item={e}
                            // setHiddenAdvState={setHiddenAdvState}
                            // hiddenAdvState={hiddenAdvState}
                            setCurrent={setCurrent}
                          />
                        );
                      })}
                </animated.div>
              )
            );
          })}
        </IonGrid>
      </IonContent>
      {/* <IonToast
        color="warning"
        isOpen={showToast1}
        onDidDismiss={() => setShowToast1(false)}
        message="Bitte beantworten Sie alle Fragen, um fortzufahren"
        duration={3000}
        position="top"
      /> */}

      <Footer
        // footerStatus={footerStatus}
        pageData={pageData}
        handleSubmit={handleSubmit}
      />
    </IonPage>

    // )
  );
};

export default Uksh;

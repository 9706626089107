import React from "react";
import {
  IonRow,
  IonButton,
  IonCol,
  IonFooter,
  IonToolbar,
  IonIcon,
} from "@ionic/react";
import { arrowForward, arrowBack } from "ionicons/icons";

const FooterDyn = ({
  mediaData ,
  pageData,
  handleSubmit,
  pageready,
  firstLastPage,
  requiredQuestionCheck,
  //  footerStatus
}) => {

  // if( ! mediaData ) {
  //   mediaData =
  //     {
  //       urlds: "https://www.procuratio.com/datenschutz/" ,
  //       urlimp: "https://www.procuratio.com/impressum/"
  //     }
  // }
  // console.log( 'mediaData 2' , mediaData ) ;

  return (
    <IonFooter>
      <IonToolbar className="toolheader">
        {
          mediaData
            ?
              mediaData.urlds || mediaData.urlimp
                ?
                  <IonRow>
                    <IonCol
                      style =
                        {
                          {
                            fontSize: "0.7em" ,
                            textAlign: "center"
                          }
                        }
                    >
                      {
                        mediaData.urlds
                          ?
                            <a
                              href = { mediaData.urlds }
                              style =
                                {
                                  {
                                    color: 'inherit' ,
                                    opacity: '0.6' ,
                                    padding: '0 0.5em 0 0.5em' ,
                                    textDecoration: 'none'
                                  }
                                }
                              target = "_blank"
                            >
                              Datenschutzerklärung
                            </a>
                          :
                            null
                      }
                      {
                        mediaData.urlimp
                          ?
                            <a
                              href = { mediaData.urlimp }
                              style =
                                {
                                  {
                                    color: 'inherit' ,
                                    opacity: '0.6' ,
                                    padding: '0 0.5em 0 0.5em' ,
                                    textDecoration: 'none'
                                  }
                                }
                              target = "_blank"
                            >
                              Impressum
                            </a>
                          :
                            null
                  }
                </IonCol>
              </IonRow>
            :
              null
          :
            null
        }
        <IonRow style={{ padding: "5px" }}>
          {/* {pageData && pageData.index === 6 ? (
            <IonCol size="12" className="centerCenter">
              <IonButton color="medium" onClick={() => handleSubmit()}>
                Start Over
              </IonButton>
            </IonCol>
          ) : ( */}

          {firstLastPage !== "last" && (
            <>
              <IonCol size="6" className="centerCenter">
                <IonButton
                  color="medium"
                  disabled={
                    firstLastPage === "first" || firstLastPage === "last"
                  }
                  onClick={() => handleSubmit("prev")}
                >
                  <IonIcon style={{ paddingRight: "5px" }} icon={arrowBack} />
                  zurück
                </IonButton>
              </IonCol>
              <IonCol size="6" className="centerCenter">
                <IonButton
                  disabled={requiredQuestionCheck ? true : false}
                  color="medium"
                  onClick={() => handleSubmit("next")}
                >
                  {/* {pageData && pageData.index === 5 ? "absenden" : "weiter"} */}
                  Weiter
                  <IonIcon style={{ paddingLeft: "5px" }} icon={arrowForward} />
                </IonButton>
              </IonCol>
            </>
          )}

          {/* )} */}
        </IonRow>
      </IonToolbar>
    </IonFooter>
  );
};

export default FooterDyn;

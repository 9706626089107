import React from "react";
import {
  IonRow,
  IonButton,
  IonCol,
  IonFooter,
  IonToolbar,
  IonIcon,
} from "@ionic/react";
import { arrowForward, arrowBack } from "ionicons/icons";

const Footer = ({
  pageData,
  handleSubmit,
  pageready,
  //  footerStatus
}) => {
  return (
    <IonFooter>
      <IonToolbar className="toolheader">
        <IonRow style={{ padding: "5px" }}>
          {/* {pageData && pageData.index === 6 ? (
            <IonCol size="12" className="centerCenter">
              <IonButton color="medium" onClick={() => handleSubmit()}>
                Start Over
              </IonButton>
            </IonCol>
          ) : ( */}
          <>
            <IonCol size="6" className="centerCenter">
              <IonButton
                color="medium"
                disabled={
                  pageready === false ||
                  (pageData && pageData.index === 0) ||
                  (pageData && pageData.index === 6)
                }
                onClick={() => handleSubmit("prev")}
              >
                <IonIcon style={{ paddingRight: "5px" }} icon={arrowBack} />
                zurück
              </IonButton>
            </IonCol>
            <IonCol size="6" className="centerCenter">
              <IonButton
                // disabled={footerStatus}
                disabled={
                  pageready === false || (pageData && pageData.index === 6)
                }
                color="medium"
                onClick={() => handleSubmit("next")}
              >
                {pageData && pageData.index === 5 ? "absenden" : "weiter"}
                <IonIcon style={{ paddingLeft: "5px" }} icon={arrowForward} />
              </IonButton>
            </IonCol>
          </>
          {/* )} */}
        </IonRow>
      </IonToolbar>
    </IonFooter>
  );
};

export default Footer;

import React, { useEffect } from "react";

const ShortCutRedirect = () => {
  let search = window.location.search;

  useEffect(() => {
    fetch(
      process.env.NODE_ENV === "production"
        ? `https://fb-backend.procuratio.com/client/customredirect/`
        : `http://localhost:3000/client/customredirect/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // customshortcut: search.substring(1),
          customshortcut: search,
        }),
      }
    )
      .then((e) => e.json())
      .then((e) => (window.location = e.link));
  }, [search]);

  return <></>;
};

export default ShortCutRedirect;
